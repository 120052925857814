import { mapActions, mapState } from "vuex";
import DatesSelectedDialog from "@/components/action-plans/components/selectedDatesField/index.vue";
import QuestionsSelectedDialog from "../components/selectedQuestionsDialog";
import ValidateActivity from '@/components/global/ValidateActivity.vue';
import { showSnackBar } from "@/helpers/globalHelpers";
import moment from "moment";
//import moment from "moment";

export default {
  name: "editActionPlan",

  data: () => ({
    loadingSend: false,
    searchQuestion: "",
    isValidForm: null,
    isLoading: false,
    selectedDates: [],
    selectedQuestions: [],
    recoveredSelectedQuestions: [],
    isLoadingUsers: false,
    isLoadingManagements: false,
    requiredRule: [(v) => !!v || "Campo requerido"],
    formAP: {
      // company_id: parseInt(localStorage.getItem("company_id")),
      service_id: null,
      description: "",
      responsible: null,
      recipients: null,
    },
    questionsHeader: [
      // { text: "Factores", sortable: false, value: "factor_name" },
      // { text: "Subfactores", sortable: false, value: "sub_factor_name" },
      // { text: "Preguntas", sortable: false, value: "question_description" },
      // { text: "", sortable: false, value: "actions", align: "right" },
      { text: "Factores", sortable: false, value: "factor.name" },
      { text: "Subfactores", sortable: false, value: "sub_factor.name" },
      { text: "Preguntas", sortable: false, value: "description" },
      { text: "", sortable: false, value: "actions", align: "right" },
    ],
  }),

  created() {
    this.loadUsers();
    this.loadManagements();
    this.loadActionPlan();
  },

  computed: {
    ...mapState("security", ["usersActive", "costCenters"]),
    ...mapState("action_plan", ["actionPlan"]),
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción por servicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidences",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "HomeActionPlans",
            serviceId: parseInt(this.$route.params?.serviceId)
          },
        },
        {
          text: "Editar plan de acción",
          link: true,
          exact: true,
          disabled: true,
          // to: {
          //   name: 'InventoryHome',
          // },
        },
      ];
    },

  },

  methods: {
    ...mapActions("security", ["getUsersActive", "getCostCenter"]),
    ...mapActions("action_plan", ["editActionPlan", "getActionPlanById"]),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.getCostCenter();
      this.isLoadingManagements = false;
    },

    loadActionPlan: async function () {
      const actionPlanId = this.$route.params?.actionPlanId;

      if (actionPlanId) {
        this.isLoading = true;
        const data = await this.getActionPlanById(actionPlanId);
        const actionPlan = data?.response;
        if (actionPlan) {
          this.formAP = {
            // company_id: parseInt(localStorage.getItem("company_id")),
            service_id: actionPlan?.service?.id,
            description: actionPlan?.description,
            responsible: actionPlan?.responsible?.id,
            recipients: [],
          };
          if (Array.isArray(actionPlan?.recipients)) {
            actionPlan?.recipients.forEach((recipient) => {
              this.formAP.recipients.push(recipient?.user_id);
            });
          }
          if (Array.isArray(actionPlan?.evidences_action_plan)) {
            actionPlan?.evidences_action_plan.forEach((item) => {
              this.selectedDates.push(
                // moment(item?.register_date).format("YYYY-MM-DD")
                moment.utc(item?.register_date).format("YYYY-MM-DD")
              );
            });
          }
          if (Array.isArray(actionPlan?.action_plan_details)) {
            const selectedQuestions = actionPlan?.action_plan_details.map(actionPlanDetail => {
              // Crear un nuevo objeto con todas las propiedades del original, excepto `question_id`
              const { question_id, ...rest } = actionPlanDetail;

              // Retornar el nuevo objeto, reemplazando `id` con el valor de `question_id` y omitiendo el original `question_id`
              return { ...rest, id: question_id };
            });

            this.selectedQuestions = /*actionPlan?.action_plan_details*/ selectedQuestions;
            this.recoveredSelectedQuestions = /*actionPlan?.action_plan_details*/ selectedQuestions;
          }
        }
        this.isLoading = false;
      }
    },

    removeQuestion: function (questionId) {
      const index = this.selectedQuestions.findIndex(
        (e) => e?.id === questionId
      );
      if (index > -1) this.selectedQuestions.splice(index, 1);
    },

    validationForm: function () {
      const formCompleted = this.$refs.form.validate();
      const periodCompleted = this.selectedDates.length > 0;
      const questionCompleted = this.selectedQuestions.length > 0;

      if (!formCompleted) {
        showSnackBar("Completar el formulario", "warning");
        return false;
      }

      if (!periodCompleted) {
        showSnackBar("Completar el período", "warning");
        return false;
      }

      if (!questionCompleted) {
        showSnackBar("Seleccionar las preguntas", "warning");
        return false;
      }

      if (formCompleted && periodCompleted && questionCompleted) {
        this.saveActionPlan();
      }
    },

    saveActionPlan: async function () {
      this.loadingSend = true;
      let data = {
        ...this.formAP,
        questions: [],
        periodicity: [],
      };

      this.selectedDates.forEach((date) => {
        data.periodicity.push({
          period_date: moment(date).toISOString(),
        });
      });


      this.selectedQuestions.forEach((question) => {
        data.questions.push(question?.id);
      });

      const actionPlanId = parseInt(this.$route.params?.actionPlanId);
      const { ok, error } = await this.editActionPlan({
        id: actionPlanId,
        payload: data,
      });
      if (ok) {
        this.$swal.fire({
          title: "¡El plan de acción fue actualizado con éxito!",
          // text: "En un momento te enviaremos la aprobación por correo para que puedas subir tu evidencia, gracias",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Ok",
        });
        this.$router.push({ name: "HomeActionPlans", params: { serviceId: this.$route.params.serviceId } });
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
        showSnackBar(errorMsm, "error");
      }
      this.loadingSend = false;
    },

    clearSelectedQuestion() {

      if (this.formAP.service_id !== this.actionPlan?.service?.id) {

        this.selectedQuestions = [];
      } else {
        this.selectedQuestions = this.recoveredSelectedQuestions;
      }
    },

    isAllowed(module, page, activityName) {

      const activities = this.$store.state.auth.activities || [];
      const found = activities.some(
        (activity) =>
          activity.module_name === module &&
          activity.page_name === page &&
          activity.activity_name === activityName
      );

      return found;
    },

    // questionItems() {
    //    this.selectedQuestions?.map(selectedQuestion => {
    //     return {
    //       factor_name: selectedQuestion?.factor?.name,
    //       sub_factor_name: selectedQuestion?.sub_factor?.name,
    //       question_description: selectedQuestion?.description,
    //       id: selectedQuestion?.id
    //     }
    //   })
    // }
  },

  components: {
    DatesSelectedDialog,
    QuestionsSelectedDialog,
    ValidateActivity
  },
};
