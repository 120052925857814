var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-4",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-card',{staticClass:"py-2 pa-md-4 mb-2",attrs:{"rounded":"lg","flat":"","loading":_vm.isLoading}},[_c('v-card-title',{staticClass:"mb-2"},[_vm._v(" Plan de acción ")]),_c('v-card-subtitle',{staticClass:"mb-2"},[_vm._v(" Completa los siguientes campos para crear tu plan de acción ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","xl":"6"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isValidForm),callback:function ($$v) {_vm.isValidForm=$$v},expression:"isValidForm"}},[_c('v-text-field',{attrs:{"label":"Actividad","placeholder":"Escriba la actividad","outlined":"","required":"","rules":_vm.requiredRule},model:{value:(_vm.formAP.description),callback:function ($$v) {_vm.$set(_vm.formAP, "description", $$v)},expression:"formAP.description"}}),_c('v-autocomplete',{attrs:{"item-value":"id","item-text":"fullName","label":"Responsable*","placeholder":"Seleccionar responsable","outlined":"","clearable":"","items":_vm.usersActive,"rules":_vm.requiredRule,"loading":_vm.isLoadingUsers},model:{value:(_vm.formAP.responsible),callback:function ($$v) {_vm.$set(_vm.formAP, "responsible", $$v)},expression:"formAP.responsible"}}),_c('v-autocomplete',{attrs:{"readonly":!_vm.isAllowed(
              'climaLaboral',
              'listaPlanesAccion',
              'editarTotalPlanAccion'
            ),"item-value":"id","item-text":"name","label":"Servicio*","placeholder":"Seleccionar Servicio","outlined":"","clearable":_vm.isAllowed(
              'climaLaboral',
              'listaPlanesAccion',
              'editarTotalPlanAccion'
            ),"items":_vm.costCenters,"rules":_vm.requiredRule,"loading":_vm.isLoadingManagements},on:{"change":function($event){return _vm.clearSelectedQuestion()}},model:{value:(_vm.formAP.service_id),callback:function ($$v) {_vm.$set(_vm.formAP, "service_id", $$v)},expression:"formAP.service_id"}}),_c('v-autocomplete',{attrs:{"item-value":"id","item-text":"fullName","label":"Usuarios a notificar*","placeholder":"Seleccionar usuarios a notificar","chips":"","multiple":"","outlined":"","clearable":"","items":_vm.usersActive,"rules":_vm.requiredRule,"loading":_vm.isLoadingUsers},model:{value:(_vm.formAP.recipients),callback:function ($$v) {_vm.$set(_vm.formAP, "recipients", $$v)},expression:"formAP.recipients"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"py-2 pa-md-4 mb-2",attrs:{"rounded":"lg","flat":""}},[_c('v-card-title',[_vm._v("Seleccionar fecha")]),_c('v-card-subtitle',[_vm._v("Seleccionar fechas de notificación")]),_c('v-card-text',[_c('dates-selected-dialog',{attrs:{"disabled":!_vm.isAllowed(
              'climaLaboral',
              'listaPlanesAccion',
              'editarTotalPlanAccion'
            )},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}})],1)],1),_c('v-card',{staticClass:"py-2 pa-md-4 mb-2",attrs:{"rounded":"lg","flat":""}},[_c('v-card-title',[_vm._v("Preguntas")]),_c('v-card-subtitle',[_vm._v(" Selecciona las preguntas para el plan de acción ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.questionsHeader,"items":_vm.selectedQuestions,"search":_vm.searchQuestion},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-2",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar preguntas","placeholder":"Ingresar descripción de pregunta","prepend-inner-icon":"mdi-magnify","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.searchQuestion),callback:function ($$v) {_vm.searchQuestion=$$v},expression:"searchQuestion"}})],1),(
                  _vm.isAllowed(
                    'climaLaboral',
                    'listaPlanesAccion',
                    'editarTotalPlanAccion'
                  )
                )?_c('v-col',{attrs:{"cols":"auto"}},[_c('questions-selected-dialog',{attrs:{"serviceId":_vm.formAP.service_id},model:{value:(_vm.selectedQuestions),callback:function ($$v) {_vm.selectedQuestions=$$v},expression:"selectedQuestions"}})],1):_vm._e()],1)]},proxy:true},(
              _vm.isAllowed(
                'climaLaboral',
                'listaPlanesAccion',
                'editarTotalPlanAccion'
              )
            )?{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"grey","title":"Quitar","icon":""},on:{"click":function($event){return _vm.removeQuestion(item?.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}:null],null,true)}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingSend},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }