<template>
  <edit-action-plan />
</template>
<script>
import EditActionPlan from "@/components/action-plans/EditActionPlan/index.vue";

export default {
  name: "EditActionPlanPage",
  components: { EditActionPlan },
};
</script>
